import { serialize } from 'object-to-formdata';
import apiClient from '../client';
import { getSafeValue } from 'utils';

export default class UserAPI {
  static update(id: string, data: any) {
    let modifiedData = data;
    if (data.profile_image !== null) {
      modifiedData = serialize(data);
      apiClient.defaults.headers.common['Content-Type'] = 'multipart/form-data'
    }
    return apiClient.put(`/user/${id}`, modifiedData);
  }

  static updateBusinessProfile(data: any) {
    let modifiedData = data;
    if (getSafeValue(data, 'logo', null) !== null) {
      apiClient.defaults.headers.common['Content-Type'] = 'multipart/form-data'
      modifiedData = serialize(data);
    }
    return apiClient.patch(`/organisations/current`, modifiedData);
  }

  static getBusinessLocations(contact_id: any = '') {
    return apiClient.get(`/organisation-frequent-locations?contact_id=${contact_id}`);
  }

  static saveBusinessLocations(data: any) {
    return apiClient.post('/organisation-frequent-locations', data);
  }

  static updateBusinessLocations(id: any, data: any) {
    return apiClient.put(`/organisation-frequent-locations/${id}`, data);
  }

  static deleteBusinessLocations(id: any) {
    return apiClient.delete(`/organisation-frequent-locations/${id}`);
  }

  static getAllUsers() {
    return apiClient.get("/user", {
      params: {
        paginate: 0,
      },
    });
  }
}