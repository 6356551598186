import { Box, Grid, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import InnerLoader from "components/Loading/InnerLoader";
import { PrimaryButton } from "components/Ui/Button/Button";
import CustomTextField from "components/Ui/TextInput/CustomTextField";
import { Formik } from "formik";
import { useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import Style from "./EditBusinessProfile.module.scss";
import { getSafeValue } from "utils";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import LeadProfileApi from "api/resources/leadProfile";
import { getViewRequest } from "redux/reducers/leadProfileReducer";

function EditBusinessProfile({ open, handleClose, businessDetails }: any) {
  const [isLoading, setIsLoading] = useState<any>(false)
  const select = useAppSelector((state: any) => state.leadProfile.select);
  const dispatch = useAppDispatch();
  
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{
        "& .MuiPaper-rounded": {
          borderRadius: "10px",
        },
      }}
    >
      <DialogContent>
        {isLoading && <InnerLoader height="auto" />}
        <Box>
          <Box onClick={() => handleClose()} className={Style.close}>
            <MdOutlineClose size={25} color="#A4A4A4" />
          </Box>
          <Typography color={"#222222"} className={Style.title} variant="heading2" component={'h2'}>
            Edit Business Details
          </Typography>
          <Formik
            initialValues={{
              company_name: getSafeValue(businessDetails, 'company_name', ''),
              company_link: getSafeValue(businessDetails, 'company_link', ''),
            }}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                setIsLoading(true)
                const para = {
                  ...businessDetails,
                  company_name: values.company_name,
                  company_link: values.company_link,
                };
                delete para.alias_image;
                delete para.cover_picture;
                delete para.logo;
                const updatedBusinessData = await LeadProfileApi.updateProfile(select, para)
                dispatch(getViewRequest(updatedBusinessData.data))
                setIsLoading(false)
                handleClose()
              } catch (error) {
                console.log(error)
                setIsLoading(false)
              }
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container columnSpacing={1.5} rowSpacing={1.5}>
                  <Grid item xs={6} md={6}>
                    <Typography variant="title2" className={Style.lable}>
                      Business Name
                    </Typography>
                    <CustomTextField
                      fullWidth
                      placeholder="Business Name"
                      variant="outlined"
                      name="company_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.company_name}
                      required
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography variant="title2" className={Style.lable}>
                      Website Url
                    </Typography>
                    <CustomTextField
                      fullWidth
                      placeholder="https://yourwebsite.com"
                      variant="outlined"
                      name="company_link"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.company_link}
                    />
                  </Grid>
                </Grid>
                <Box pt={2.5}>
                  <PrimaryButton type="submit" fullWidth>Save Changes</PrimaryButton>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default EditBusinessProfile;
