import { Box, Button, Dialog, Typography } from "@mui/material";
import CloseIcon from 'assets/icon/close.svg';
import Style from "./Footer.module.scss";

const MissingInformationAlert = ({ onPopupClose, handleBack, handleContinue }: any) => {
  
  const handleClose = (event?: any, reason?: string) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      onPopupClose(false);
    }
  };

  return (
    <Dialog
      open={true}
      maxWidth='sm'
      disableEscapeKeyDown
      onClose={(event, reason) => handleClose(event, reason)}
      PaperProps={{ sx: { borderRadius: "10px", width: '558px' } }}
    >
      <Box className={Style.model}>
        <Box className={Style.modelHeader}>
          <Typography className={Style.modelTitle} variant="title">Missing Quote Delivery Information</Typography>
          <span className={Style.modelCloseIcon} onClick={handleClose}>
            <img src={CloseIcon} alt="model Close icon" />
          </span>
        </Box>
        <Box className={Style.modelBody}>
          <Typography className={Style.modelInformation}>Please note: The quote will not be sent to your customer, but it will be retained in your Kepler CRM system.</Typography>
        </Box>
        <Box className={Style.modelFooter}>
          <Button
            onClick={handleBack}
            className={Style.backBtn}
          >Go Back</Button>
          <Button
            onClick={handleContinue}
            className={Style.continueBtn}
          >Continue Anyway</Button>
        </Box>
      </Box>
    </Dialog>

  );
};

export default MissingInformationAlert;
