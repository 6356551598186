import { styled } from "@mui/material/styles";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";

interface BpIconProps {
  color?: string;
}

interface CustomCheckboxProps extends CheckboxProps {
  checkedColor?: string;
}

const BpIcon = styled("span")<BpIconProps>(({ theme }) => ({
  borderRadius: 5,
  width: 17,
  height: 17,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px #CECECE, inset 0 -1px 0 #CECECE",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : 'transparent',
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    backgroundColor: "#dedede"
  }
}));

const BpCheckedIcon = styled(BpIcon)<BpIconProps>(({ color = "#0085DD" }) => ({
  backgroundColor: color,
  boxShadow: "none",
  "&:before": {
    display: "block",
    width: 17,
    height: 17,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: color,
  },
  "input:disabled ~ &": {
    backgroundColor: "#c5c3c3",
  },
}));

export default function BpCheckbox(props: CustomCheckboxProps) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
        p: 0,
        mr: '9px',
        borderRadius: '5px',
        border: '1px solid transparent',
        "&.Mui-focusVisible": {
          backgroundColor: '#ebf1f5',
          outline: '2px solid #ebf1f5'
        }
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon color={props.checkedColor || '#0085DD'} />}
      icon={<BpIcon />}
      {...props}
    />
  );
}
