import { FormControl, MenuItem } from "@mui/material";
import classes from "./Select.module.scss";
import { BiChevronDown } from "react-icons/bi";
import Select from "@mui/material/Select";
import InputTooltip from "components/Ui/InputTooltip/InputTooltip";

interface IFTextField {
  label?: string;
  value?: string;
  name: string;
  onChange?: any;
  options: any;
  placeholderOption?: any,
  renderValue?: any,
  tooltipText?: string,
  required?: boolean
  className?: string,
  disabled?: boolean
}

const SelectDropdown = ({ label, value, name, onChange, options, placeholderOption, renderValue, tooltipText, required = false, className = '', disabled = false }: IFTextField) => {
  return <FormControl fullWidth variant="outlined" className={classes[className]}>
    {label &&
      <label className={classes.inputLabel} htmlFor={name}>{label} {tooltipText && <InputTooltip text={tooltipText} />}</label>
    }
    <Select
      name={name}
      id={name}
      className={classes.SelectField}
      value={value}
      onChange={onChange}
      renderValue={renderValue}
      IconComponent={BiChevronDown}
      required={required}
      disabled={disabled}
      sx={{
        '& .MuiSelect-select': {
          color: '#000',
          fontSize: '16px',
          fontWeight: '500',
          lineHeight: '24px'
        }
      }}
    >
      {placeholderOption && <MenuItem className={`${classes.selectOptions} ${classes[className]}`} value={'0'}>{placeholderOption}</MenuItem>}
      {options.map((option: any, index: number) => (<MenuItem className={`${classes.selectOptions} ${classes[className]}`} key={`${name}_${index}`} value={option.value}>{option.label}</MenuItem>))}
    </Select>
  </FormControl>;
};

export default SelectDropdown;
