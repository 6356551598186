import { Box, Button, Grid, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import LeadProfileApi from "api/resources/leadProfile";
import { ButtonBox, PrimaryButton } from "components/Ui/Button/Button";
import { httpService } from "helper/httpService";
import React, { useEffect, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { toast } from "react-toastify";
import { refreshRequest } from "redux/reducers/leadProfileReducer";
import AvatarEditorModal from "../AvatarEditorModal";
import Warning from "../WarningModal/Warning";
import Style from "./UpdateCover.module.scss";
import InnerLoader from "components/Loading/InnerLoader";
import placeholderImage from 'assets/placeholder.jpg'
import { getSafeValue } from "utils";

function UpdateCover({ open, handleClose, type }: any) {
  const imageInput: any = React.useRef(null);
  const [coverList, setCoverList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selected, isSelected] = useState(null);

  const [isAvatarModalOpen, setIsAvatarModalOpen] = useState(false)
  const [newUserAvatarImage, setNewUserAvatarImage] = useState<any>(null);

  useEffect(() => {
    getCoverImages()
  }, []);

  const getCoverImages = async () => {
    setIsLoading(true)
    const response = await LeadProfileApi.getImageList("Cover Picture");
    setCoverList(response.data.data);
    setIsLoading(false)
  }

  const [coverImagePreviewData, setCoverImagePreviewData] = useState<any>()
  const handleExport = async (data: any) => {
    setCoverImagePreviewData(data)
    setIsLoading(true)
    const formData = new FormData();
    formData.append("uploads[0][file]", data.file);
    formData.append("uploads[0][type]", "Cover Picture");
    const uploadData = await LeadProfileApi.postCoverImage(formData);
    const selectedImage = getSafeValue(uploadData, 'data.data[0].id', 0)
    isSelected(selectedImage)
    setIsLoading(false)
    getCoverImages()
    setCoverImagePreviewData(null)
  };

  const handleCover = async () => {
    if (selected) {
      httpService(
        () => LeadProfileApi.uploadCoverPicture(type, selected),
        refreshRequest
      );
      handleClose();
    } else {
      toast.error("Select Cover Image");
    }
  };

  const [removeImage, setRemoveImage] = useState<any>(null)
  const [confirmImageWarning, showConfirmImageWarning] = useState(false)
  const confirmRemoveImage = (item: any) => {
    setRemoveImage(item)
    showConfirmImageWarning(true)
  }

  const handleRemoveImage = async () => {
    try {
      setIsLoading(true)
      await LeadProfileApi.deleteCoverPicture(removeImage.id)
      const imageList = coverList.filter((image: any) => image.id !== removeImage.id)
      setCoverList(imageList)
      showConfirmImageWarning(false)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      showConfirmImageWarning(false)
      setIsLoading(false)
    }
  }

  const onProfileImageChange = (event: any) => {
    const allowedImageType = ['image/jpg', 'image/jpeg', 'image/png']
    if (allowedImageType.includes(event.target.files[0].type)) {
      if (event.target.files[0]) {
        const objectUrl = URL.createObjectURL(event.target.files[0]);
        setNewUserAvatarImage(objectUrl);
        setIsAvatarModalOpen(true);
      }
    } else {
      toast.error("Only jpg, jpeg and png are allowed.");
    }
  };

  const handleUploadImageClick = async () => {
    if (coverImagePreviewData) {
      setIsLoading(true)
      const formData = new FormData();
      formData.append("uploads[0][file]", coverImagePreviewData.file);
      formData.append("uploads[0][type]", "Cover Picture");
      await LeadProfileApi.postCoverImage(formData);
      setIsLoading(false)
      getCoverImages()
      setCoverImagePreviewData(null)
    } else {
      imageInput.current.click()
    }
  }

  const handleResetImage = () => {
    setCoverImagePreviewData(null)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
      fullWidth
      PaperProps={{ sx: { borderRadius: "10px", width: '750px', position: 'relative' } }}
    >
      <DialogContent>
        {isLoading === true && <InnerLoader height={'auto'} />}
        <Box>
          <Box pb={2}>
            <Box onClick={() => handleClose()} className={Style.close}>
              <MdOutlineClose className="cursor" size={25} color="#A4A4A4" />
            </Box>
            <Typography variant="title" className={Style.title}>
              Update Cover Picture
            </Typography>
            <Typography className={Style.subtitle}>
              Choose a cover picture or upload your own
            </Typography>
          </Box>
          {!isAvatarModalOpen && (
            <Box>
              <Grid container rowSpacing={2.5} columnSpacing={2.5}>
                {coverList &&
                  coverList.length > 0 &&
                  coverList.map((item: any, i: any) => (
                    <Grid item md={6} key={i}>
                      <Box
                        className={`${Style.ImageSelect} ${item.id === selected && Style.slected
                          }`}
                        onClick={() => isSelected(item.id)}
                      >
                        {item.id !== selected && <Box onClick={() => confirmRemoveImage(item)} className={Style.removeCoverImage}>
                          <MdOutlineClose className="cursor" size={25} color="#FFFFFF" />
                        </Box>}
                        <img src={item.url} className={Style.imageSize} alt="" />
                      </Box>
                    </Grid>
                  ))}
              </Grid>
              <Grid container rowSpacing={2.5} columnSpacing={2.5}>
                <Grid item md={12}>
                  <Box className={Style.coverImageUpload}>
                    <Box className={Style.imagePreviewArea}>
                      <img src={coverImagePreviewData ? coverImagePreviewData.blobUrl : placeholderImage} alt="Placeholder" />
                    </Box>
                    <Box display={'flex'} flexDirection={'column'} gap={'10px'}>
                      <Typography variant="inputText" fontWeight={'400'}>
                        Recommended dimensions of <strong>600x200</strong>
                      </Typography>
                      <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                        <ButtonBox
                          className="dark"
                          onClick={() => handleUploadImageClick()}
                        >{coverImagePreviewData ? 'Upload' : 'Select'} Image</ButtonBox>
                        <input
                          style={{ display: "none" }}
                          ref={imageInput}
                          type="file"
                          accept="image/jpg"
                          onChange={onProfileImageChange}
                        />
                        {coverImagePreviewData && <Button
                          variant="text"
                          color="error"
                          sx={{ textTransform: 'capitalize' }}
                          onClick={handleResetImage}
                        >Remove Image</Button>}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
          <AvatarEditorModal
            open={isAvatarModalOpen}
            close={setIsAvatarModalOpen}
            handle={handleExport}
            image={newUserAvatarImage}
            title='Update Cover Picture'
            height={200}
            width={600}
            buttonText={'Crop and Upload Image'}
            buttonType={'primary'}
          />
          <Warning
            title="Warning"
            content="Are you sure you want to delete this? It can't be undone"
            open={confirmImageWarning}
            close={() => showConfirmImageWarning(false)}
            handle={handleRemoveImage}
            darkButton={true}
            loading={isLoading}
          />
          <Box pt={2.5}>
            <PrimaryButton
              type="submit"
              fullWidth
              onClick={() => handleCover()}
            >
              Save Changes
            </PrimaryButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default UpdateCover;
