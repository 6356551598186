/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from "@mui/material";
import LeadProfileApi from "api/resources/leadProfile";
import UpdateCover from "components/Modal/UpdateCover/UpdateCover";
import UpdateProfile from "components/Modal/UpdateProfile/UpdateProfile";
import { RoundButton } from "components/Ui/Button/Button";
import moment from "moment";
import { useEffect, useState } from "react";
import { HiCamera } from "react-icons/hi";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { activeTabKProfile, contactProjects, selectTabs } from "redux/reducers/leadProfileReducer";
import { formatPhoneNumber, getSafeValue } from "utils";
import AboutUs from "./AboutUs";
import Style from "./AutomotiveProfile.module.scss";
import Contact from "./Contact";
import EditBusinessProfile from "./EditBusinessProfile";
import Projects from "./Projects";
import TabBar from "./TabBar/TabBar";
import Updates from "./Updates";
import { useSelector } from "react-redux";

function AutomotiveProfile({ isPublic, isPreview = false }: any) {
  const dispatch = useAppDispatch();
  const [openProfile, setOpenProfile] = useState(false);
  const [openCover, setOpenCover] = useState(false);
  const data = useAppSelector((state: any) => state.leadProfile.data);
  const select = useAppSelector((state: any) => state.leadProfile.select);
  const color = useAppSelector((state: any) => state.leadProfile.primaryColor);
  const k_profile_tab = useAppSelector((state: any) => state.leadProfile.k_profile_tab);
  const projects = useAppSelector((state) => state.leadProfile.k_profile_projects);
  const publicData = useAppSelector((state) => state.leadProfile.publicData);
  const user = useSelector((state: any) => state.auth.entities)

  const [openEditProfile, setOpenEditProfile] = useState(false);
  const [businessInformation, setBusinessInformation] = useState<any>(null)

  const handleClose = () => {
    setOpenProfile(false);
    setOpenCover(false);
  };

  useEffect(() => {
    dispatch(selectTabs("automotive"));
  }, []);

  useEffect(() => {
    setBusinessInformation({
      contact_number: getSafeValue(publicData, 'organisation.contact_number', getSafeValue(user, 'organisation.contact_number', '')),
      business_name: getSafeValue(publicData, 'organisation.name', getSafeValue(user, 'organisation.name', '')),
      email: getSafeValue(publicData, 'organisation.business_email', getSafeValue(user, 'organisation.business_email', '')),
      business_website: getSafeValue(publicData, 'organisation.business_website', getSafeValue(user, 'organisation.business_website', '')),
      hide_address_from_profile: getSafeValue(publicData, 'organisation.hide_address_from_profile', getSafeValue(user, 'organisation.hide_address_from_profile', '')),
      hide_email_from_profile: getSafeValue(publicData, 'organisation.hide_email_from_profile', getSafeValue(user, 'organisation.hide_email_from_profile', '')),
      hide_website_from_profile: getSafeValue(publicData, 'organisation.hide_website_from_profile', getSafeValue(user, 'organisation.hide_website_from_profile', '')),
      address: getSafeValue(publicData, 'organisation.address', getSafeValue(user, 'organisation.address', '')),
    })
  }, [publicData, user]);

  useEffect(() => {
    if (publicData) {
      const { contact_id } = publicData
      getContactProject(contact_id)
    }
  }, [publicData])

  const handleTabChange = (tab: any) => {
    dispatch(activeTabKProfile(tab))
  }

  const getContactProject = async (contact_id: any) => {
    if (projects.length === 0) {
      try {
        const data = await LeadProfileApi.getKProfileContactProject(contact_id)

        const allProjects = getSafeValue(data, 'data.data', []).filter((project: any) => project.type === 'Automotive').map((project: any) => {
          return {
            campaign_id: getSafeValue(project, 'your_quote_lead_campaign_email_uuid', ''),
            status: getSafeValue(project, 'status', ''),
            created_at: getSafeValue(project, 'created_at', '') ? moment(getSafeValue(project, 'created_at', '')).format('MM.DD.YY') : '',
            name: `${getSafeValue(project, 'type_details.make', '---')} ${getSafeValue(project, 'type_details.model', '---')} (${getSafeValue(project, 'type_details.year', '---')})`,
            type: `${getSafeValue(project, 'type', '')} Window Tinting`,
            completed_at: getSafeValue(project, 'completed_at', '') !== '' ? moment(getSafeValue(project, 'completed_at', '')).format('MM.DD.YY') : '',
          }
        })
        dispatch(contactProjects(allProjects))
      } catch (error) {
        console.log(error)
      }

    }
  }

  return (
    <Box>
      <Box className={Style.header}>
        <Box
          className={Style.banner}
          sx={{
            backgroundImage: `url(${data?.cover_picture ? data.cover_picture : "/assets/images/leadProfile/banner.png"})`,
          }}
        >
          {!isPublic && (
            <Box className={Style.bannerAdd} onClick={() => setOpenCover(true)}>
              <HiCamera color={"#b3b3b3"} size={25} />
              <Typography className={Style.bannerAddText} color={"#b3b3b3"}>
                Change Cover Photo
              </Typography>
            </Box>
          )}
        </Box>
        <Box className={Style.bannerContent}>
          <Box className={Style.containerLogo}>
            <Box className={Style.logoMain}>
              <Box className={Style.logoContainer}>
                <Box className={Style.logoBox}>
                  {data && <img src={data?.logo} alt="logo" />}
                </Box>
                {!isPublic && (
                  <Box
                    className={Style.addlogo}
                    onClick={() => setOpenProfile(true)}
                  >
                    <HiCamera color={"#000"} size={20} />
                  </Box>
                )}
              </Box>
              <Box>
                <Typography className={Style.text1}>{businessInformation?.business_name}</Typography>
                {businessInformation?.hide_website_from_profile === false && <Typography className={Style.text2}>{businessInformation?.business_website}</Typography>}
                <Typography className={`${Style.text2} cursor`} onClick={() => window.open(`tel:${businessInformation?.contact_number}`, '_self')}>{formatPhoneNumber(businessInformation?.contact_number)}</Typography>
              </Box>
            </Box>
            <RoundButton sx={{ backgroundColor: color, ':hover': { backgroundColor: color }, fontSize: '15px' }} onClick={() => { }}>Get New Quote</RoundButton>
          </Box>
          <TabBar
            activeTab={k_profile_tab}
            setActiveTab={handleTabChange}
            color={color}
          />
        </Box>
      </Box>
      {data && <Box pt={2}>
        {k_profile_tab === "Updates" && (
          <Updates isPublic={isPublic} color={color} isPreview={isPreview} />
        )}
        {k_profile_tab === "Projects" && (
          <Projects isPublic={isPublic} color={color} isPreview={isPreview} />
        )}
        {k_profile_tab === "About Us" && (
          <AboutUs isPublic={isPublic} color={color} isPreview={isPreview} />
        )}
        {k_profile_tab === "Contact" && (
          <Contact isPublic={isPublic} color={color} isPreview={isPreview} businessInformation={businessInformation} />
        )}
      </Box>}

      {!isPublic && (<>
        <UpdateProfile open={openProfile} handleClose={handleClose} />
        <UpdateCover open={openCover} handleClose={handleClose} type={select} />
      </>)}
      <EditBusinessProfile
        open={openEditProfile}
        handleClose={() => setOpenEditProfile(false)}
        businessDetails={data}
      />
    </Box>
  );
}

export default AutomotiveProfile;
