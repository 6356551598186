import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setValidationError } from 'redux/reducers/quickQuoteReducer';
import { getQuickQuote, selectQuickQuoteInfo } from 'redux/selectors/quickQuoteSelector';
import { getSafeValue } from 'utils';
import { validateData } from 'utils/validation';
import {
  ApplicationFormSchema,
  ContactDetailsSchema,
  ContactFormSchema,
  JobDetailFormSchema
} from 'utils/validation/quote';
import * as Yup from 'yup';

export const useQuickQuoteValidation = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => getQuickQuote(state)); // Use selector to fetch `data`
  const { errors: validationError }: any = useSelector((state) => selectQuickQuoteInfo(state));
  const [isShowMissingInformationAlert, setIsShowMissingInformationAlert] = useState(false);

  const getSchema = (step: number) => {
    switch (step) {
      case 0:
        return ContactFormSchema;
      case 1:
        return JobDetailFormSchema;
      case 2:
        return ApplicationFormSchema;
      case 3:
        return ContactDetailsSchema;
      default:
        return null;
    }
  };

  const validateFormWithSchema = useCallback(
    async (schema: any, data: any): Promise<boolean> => {
      dispatch(setValidationError(null));
      const errors = await validateData(schema, data);
      if (errors?.isValidate === false) {
        dispatch(setValidationError(errors.errors));
        return false;
      }
      return true;
    },
    [dispatch],
  );

  const validateForm = useCallback(
    async (step: number, ignoreValidation = false): Promise<boolean> => {
      dispatch(setValidationError(null));
      const schema = getSchema(step);
      if (!schema) return true;

      const errors = await validateData(schema, data);
      if (errors?.isValidate === false) {
        if (ignoreValidation) {
          return true
        }
        if (step === 3) {
          if (Object.keys(errors?.errors).length === 1 && errors?.errors?.contact) {
            setIsShowMissingInformationAlert(true);
          }
        }
        dispatch(setValidationError(errors.errors));
        return false;
      }
      return true;
    },
    [dispatch, data],
  );

  const validateField = useCallback(
    async (step: number, fieldPath: string) => {
      const schema = getSchema(step);
      if (!schema) return true;
      const fieldSchema = Yup.reach(schema, fieldPath);
      const fieldValue = getSafeValue(data, fieldPath, '');

      const errors = await validateData(fieldSchema, fieldValue)
      if (getSafeValue(errors, 'isValidate', false) === false) {
        dispatch(setValidationError({ ...validationError, [fieldPath]: getSafeValue(errors, 'errors.0', '') }));
        return false;
      } else {
        dispatch(setValidationError({ ...validationError, [fieldPath]: '' }));
      }
      return true;
    },
    [dispatch, data, validationError],
  );

  return {
    validateFormWithSchema,
    validateForm,
    validateField,
    isShowMissingInformationAlert,
    setIsShowMissingInformationAlert,
  };
};
