import { Box, Grid, TextField, Typography } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
const CustomTextField = styled(TextField)({
  "& .MuiInputLabel-root ": {
    color: "#d1d1d1",
    fontSize: 16,
    fontWeight: 500,
  },
  "& label.Mui-focused": {
    color: "#A0AAB4",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "7px",
    "& .MuiInputBase-input": {
      padding: "12px 10px",
    },
    "& fieldset": {
      borderColor: "#e4e4e4",
    },
    "&:hover fieldset": {
      borderColor: "#e4e4e4",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#e4e4e4",
    },
  },
});
export default CustomTextField;
