import { Avatar, Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { formatTime, getSafeValue } from "utils";
import uuid4 from "uuid4";
import ActiveBox from "./ActiveBox";
import Style from "./Updates.module.scss";

function Updates({ isPublic, color, isPreview }: any) {
  const data = useAppSelector((state: any) => state.leadProfile.data);
  const publicData = useAppSelector((state) => state.leadProfile.publicData);
  const leadCampaignEmails = getSafeValue(publicData, 'lead_campaign.lead_campaign_emails', false)
  const [emailData, setEmailData] = useState<any>([{
    color: getSafeValue(data, 'message_from_user.color', color),
    image: getSafeValue(data, 'message_from_user.profile_image_url', null),
    name: `${getSafeValue(data, 'message_from_user.first_name', '')} ${getSafeValue(data, 'message_from_user.last_name', '')}`,
    sent_at: 'Today @12.22pm',
    is_new: true,
    subject: 'Hello <i>Customer Name</i>, thank you for allowing us to quote your project!',
    message: `<p>Here is where your lead campaign messages will appear, depending on your campaign being sent.</p>`
  }])

  useEffect(() => {
    setEmailData([{
      color: getSafeValue(data, 'message_from_user.color', color),
      image: getSafeValue(data, 'message_from_user.profile_image_url', null),
      name: `${getSafeValue(data, 'message_from_user.first_name', '')} ${getSafeValue(data, 'message_from_user.last_name', '')}`,
      sent_at: 'Today @12.22pm',
      is_new: true,
      subject: 'Hello <i>Customer Name</i>, thank you for allowing us to quote your project!',
      message: `<p>Here is where your lead campaign messages will appear, depending on your campaign being sent.</p>`
    }])
  }, [data])

  useEffect(() => {
    if (leadCampaignEmails !== false) {
      let campaignEmails = []
      for (const emails of leadCampaignEmails) {
        campaignEmails.push({
          color: getSafeValue(data, 'message_from_user.color', color),
          image: getSafeValue(data, 'message_from_user.profile_image_url', null),
          name: `${getSafeValue(data, 'message_from_user.first_name', '')} ${getSafeValue(data, 'message_from_user.last_name', '')}`,
          send_at: emails.send_at ? formatTime(emails.send_at) : '',
          is_new: true,
          subject: emails.content_subject,
          message: emails.content
        })
      }
      setEmailData(campaignEmails)
    }
  }, [publicData])

  return (emailData.map((data: any, key: number) => (
    <Box className={Style.mainContainer} key={uuid4()} mb={'20px'}>
      <Box className={Style.flexRow}>
        <Box className={Style.userInfo}>
          <Box className={Style.useBox} borderColor={color}>
            {(data.image !== null) ? <Avatar sx={{ height: '60px', width: '60px' }} alt={data.name} src={data.image} />
              : <Avatar sx={{ height: '60px', width: '60px', fontSize: '25px' }}>{data.name[0].toUpperCase()}</Avatar>}
          </Box>
          <Box>
            <Typography variant="tooltipText" fontWeight={'500'} color={'#838383'}>
              Sent by{" "}
              <Typography variant="bodyTextNormal" fontWeight={'700'} color={color}>{data.name}</Typography>
            </Typography>
            <Typography variant="tooltipText" fontWeight={'700'} color={color} component={'p'} mt={'5px'}>{data.send_at}</Typography>
          </Box>
        </Box>
        {(key === 0 && data.is_new === true && isPublic) && <Typography className={Style.nexText}>New</Typography>}
      </Box>
      <Box className={Style.paraContainer} fontStyle={isPublic ? 'initial' : 'italic'} dangerouslySetInnerHTML={{ __html: data.message }} />
      {key === 0 && <Box mt={3}>
        <ActiveBox isPublic={isPublic} color={color} isPreview={isPreview} />
      </Box>}
    </Box>
  )))
}

export default Updates;
