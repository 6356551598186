import { FormControl, TextField } from "@mui/material";
import InputTooltip from "components/Ui/InputTooltip/InputTooltip";
import { ChangeEventHandler, useEffect, useState } from "react";
import classes from "./TextInput.module.scss";
import { LinkButton } from "components/Ui/Button/Button";
import { formatPhoneNumber } from "utils";

interface IFTextField {
  label?: string;
  value?: string;
  name: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  placeholder?: string;
  multiline?: boolean;
  rows?: number
  inputProps?: any,
  tooltipText?: string
  previewLink?: string
  disabled?: boolean;
  mask?: "phone" | "none";
}

const TextInput = ({
  label = '',
  value,
  name,
  onChange,
  placeholder,
  multiline = false,
  rows = 0,
  inputProps,
  tooltipText,
  previewLink,
  disabled = false,
  mask = "none"
}: IFTextField) => {
  const [maskedValue, setMaskedValue] = useState(mask === "none" ? value : formatPhoneNumber(value))
  
  useEffect(() => {
    setMaskedValue(mask === "none" ? value : formatPhoneNumber(value))
  }, [value])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let newValue = e.target.value;
    if (mask === "phone") {
      newValue = formatPhoneNumber(newValue)
    }
    setMaskedValue(newValue);
    onChange?.(e);
  };

  return <FormControl fullWidth variant="outlined">
    {label !== '' &&
      <label className={classes.inputLabel} htmlFor={name}>
        <span className={classes.inputLabelText}>{label} {tooltipText && <InputTooltip text={tooltipText} />}</span>
        {previewLink && <LinkButton
          className="btn-link"
          sx={{ fontSize: '12px' }}
          onClick={() => window.open(previewLink, "_blank")}
          title={previewLink}
        >Preview</LinkButton>}
      </label>
    }
    <TextField
      id={name}
      className={classes.TextField}
      variant="outlined"
      defaultValue={value}
      value={maskedValue}
      name={name}
      onChange={handleInputChange}
      placeholder={placeholder}
      multiline={multiline}
      rows={rows}
      InputProps={inputProps}
      disabled={disabled}
    />
  </FormControl>;
};

export default TextInput;
