import { Box, Avatar, MenuItem, Select, Typography } from "@mui/material";
import Style from "./AssigneeDropdown.module.scss";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAppSelector } from "redux/hooks";
import _ from 'lodash'
import { useEffect, useState } from "react";
import { CheckUserHasPermission } from "components/HasPermission/HasPermission";

const AssigneeDropdown = ({
  teamMember = '',
  onChange,
  marginLeft = '16px',
  onAssigneeNameChange = () => { },
  className = '',
  dropdownFor = 'schedule',
  disabled = false,
  showName = false
}: any) => {
  const userData: any = useAppSelector((state) => state.auth.entities);
  const { users: team } = useAppSelector((state: any) => state.users);
  const handleAssigneeChange = (e: any) => {
    const { value } = e.target
    onChange(value.value)
    onAssigneeNameChange(value.label)
  }

  const [selectedMember, setSelectedMember] = useState<any>(userData.id)
  useEffect(() => {
    setSelectedMember(teamMember)
  }, [teamMember])

  let assigneeUsers = [{
    value: userData?.id,
    label: `${userData?.first_name} ${userData?.last_name}`,
    icon: userData?.profile_image,
  }]

  if (dropdownFor === 'schedule' && CheckUserHasPermission(['enable_team_scheduling'])) {
    assigneeUsers = [...assigneeUsers, ...team]
  }

  if (dropdownFor === 'toady_work' && CheckUserHasPermission(['enable_today_s_work_of_team'])) {
    assigneeUsers = [...assigneeUsers, ...team]
  }

  if (dropdownFor === 'task' && CheckUserHasPermission(['enable_team_tasks'])) {
    assigneeUsers = [...assigneeUsers, ...team]
  }

  return <Box className={Style[className]}>
    <Select
      className={Style.selectTeamMember}
      fullWidth
      color="secondary"
      variant="outlined"
      placeholder="Assignee"
      name="assignees"
      onChange={handleAssigneeChange}
      defaultValue={selectedMember}
      value={selectedMember}
      IconComponent={disabled === false ? KeyboardArrowDownIcon : undefined}
      disabled={disabled}
      sx={{
        marginLeft: marginLeft,
        width: disabled === true ? '38px !important' : (showName === true ? 'max-content' : '70px')
      }}
      renderValue={(selected: any) => {
        const member = assigneeUsers.find((item: any) => _.get(selected, 'value', selected) === item.value)

        return <Box display={'flex'} alignItems={'center'} gap={'5px'}>
          {(member?.icon !== null) ? <Avatar className={Style.selectedIcon} alt={member?.label} src={member?.icon} />
            : <Avatar className={Style.selectedIcon}>{member?.label[0].toUpperCase()}</Avatar>}
          {showName === true ? member?.label : ''}</Box>
      }}
    >
      {assigneeUsers.map((item: any, i: number) => (
        <MenuItem value={item} key={item.value}>
          <>
            {item.icon !== null ? <Avatar alt={item.label} src={item.icon} /> : <Avatar>{item.label[0].toUpperCase()}</Avatar>}
            <Typography sx={{ ml: 2 }}>{item.label}</Typography>
          </>
        </MenuItem>
      ))}
    </Select>
  </Box>
};

export default AssigneeDropdown;