import { serialize } from 'object-to-formdata';
import apiClient from '../client';

export default class TeamAPI {
  static getTeam(page: any, perPage: any, role: string) {
    return apiClient.get('/team-members', { params: { perPage: perPage, page: page, filter: { role }, paginate: 0 } });
  }

  static addTeamMember(data: any) {
    let modifiedData = data;
    if (data.profile_image !== null) {
      apiClient.defaults.headers.common['Content-Type'] = 'multipart/form-data'
      modifiedData = serialize(data);
    }
    return apiClient.post('/team-members', modifiedData);
  }

  static getTeamPermissions() {
    return apiClient.get('/organisation-permissions');
  }

  static saveTeamPermissions(data: any) {
    return apiClient.post('/organisation-permissions', data);
  }

  static downloadTeamPermissions() {
    return apiClient.get('/organisation-permissions/download-permissions');
  }

  static uploadTeamPermissions(data: any) {
    apiClient.defaults.headers.common['Content-Type'] = 'multipart/form-data'
    return apiClient.post('/organisation-permissions/upload-permissions', serialize(data));
  }

  static sendPasswordResetLink(data: any) {
    return apiClient.post('/forgot-password', data);
  }

  static suspendMember(id: any, data: any) {
    return apiClient.post(`/team-members/${id}/update-status`, data);
  }

  static deleteContact(id: any) {
    return apiClient.delete(`/team-members/${id}`);
  }

  static updateTeamMember(id: any, data: any) {
    let modifiedData = data; 
    if (data.profile_image !== null) {
      apiClient.defaults.headers.common['Content-Type'] = 'multipart/form-data'
      modifiedData = serialize(data);
    }
    return apiClient.put(`/team-members/${id}`, modifiedData);
  }
}