/* eslint-disable object-curly-newline */
import apiClient from "../client";

export default class LeadFormAPI {
  static list(para: any) {
    return apiClient.get("/lead-forms", {
      params: para,
    });
  }

  static view(id: any) {
    return apiClient.get(`/lead-forms/${id}/show`);
  }

  static stats({ id, group, from, to }: any) {
    return apiClient.get(`/lead-forms/${id}/stats`, {
      params: {
        group,
        from,
        to,
      },
    });
  }

  static create(data: any) {
    return apiClient.post("/lead-forms", data);
  }

  static duplicate(id: any) {
    return apiClient.post(`/lead-forms/${id}/duplicate`);
  }

  static update(data: any) {
    return apiClient.post("/lead-forms/update", data);
  }

  static submit(id: any, data: any) {
    return apiClient.post(`/lead-forms/${id}/submit`, data);
  }

  static delete(id: any) {
    return apiClient.delete(`/lead-forms/${id}`);
  }

  static publicView(id: any) {
    return apiClient.get(`/public/lead-forms/${id}`);
  }
  static getFormsLiveMovements() {
    return apiClient.get(`/lead-forms-live-movements?limit=16`);
  }

  static publicSubmit(data: any) {
    return apiClient.post("/public/lead-form/submit", data);
  }

  static dropdown(url: string) {
    return apiClient.get(url);
  }
  static getTabDetails() {
    return apiClient.get("/get-lead-form-tab-details");
  }
  static updateStatus(id: any, data: any) {
    return apiClient.put(`/lead-forms/${id}/update-status`, data);
  }
  static statisticsStats(id: any, data: any) {
    return apiClient.get(`/lead-statistics/${id}/stats`, {
      params: data,
    });
  }
  static statisticsChart(id: any, data: any) {
    return apiClient.get(`/lead-statistics/${id}/chart`, {
      params: data,
    });
  }
  static statisticsSubmissions(id: any, data: any) {
    return apiClient.get(`/lead-statistics/${id}/submissions`, {
      params: { ...data },
    });
  }

  static submitAutomotiveForm(data: any) {
    return apiClient.post("/public/automotive-lead-form-submission", data);
  }

  static submitArchitecturalForm(data: any) {
    return apiClient.post("/public/architectural-lead-form-submission", data);
  }
}
