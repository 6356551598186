/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Box,
  FormControlLabel,
  Grid,
  MenuItem,
  Typography
} from '@mui/material';
import CarDetailsAPI from 'api/resources/carDetails';
import classNames from 'classnames';
import ErrorMessage from "components/Ui/ErrorMessage/ErrorMessage";
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { getCarModelsRequest, getCarYearsRequest } from 'redux/reducers/carDetailsReducer';
import { updateQuoteJob } from 'redux/reducers/quickQuoteReducer';
import { getSafeValue } from 'utils';
import BpCheckbox from "components/Ui/CheckBox/BpCheckbox";
import TextInput from "components/Ui/TextInput/TextInput";
import styles from "./Job.module.scss";
import { useQuickQuoteValidation } from 'hooks/useQuickQuoteValidation';
import { selectQuickQuoteInfo } from 'redux/selectors/quickQuoteSelector';

const JobTypes = [
  {
    value: 'Automotive',
    label: 'Automotive'
  },
  {
    value: 'Architectural',
    label: 'Architectural'
  }
];

interface JobDetailProps {
  job: any
  index: number
  validationError?: any
}

function JobDetail({ job, index, validationError }: JobDetailProps) {
  const dispatch = useDispatch();
  const { makes, models, years, carTypes } = useAppSelector((state: any) => state.carDetails);
  const [additionalInput, setAdditionalInput] = useState(false);
  
  const { active_step }: any = useAppSelector((state) => selectQuickQuoteInfo(state));
  const { validateField } = useQuickQuoteValidation()
  const handleBlur = async (fieldPath: string) => {
    await validateField(active_step, fieldPath);
  };

  useEffect(() => {
    const make = getSafeValue(job, 'make', '')
    if (make !== '') {
      const makeId = makes.find((item: any) => item.make === make);
      getCarModels(makeId.id, true)
    }
    if (job.color || job.vin || job.license) {
      setAdditionalInput(true);
    }
  }, [])

  const getCarYears = async (modelId: number) => {
    try {
      const data = await CarDetailsAPI.listYears(modelId);
      const years = data.data.sort((a: any, b: any) => b.year - a.year)
      dispatch(getCarYearsRequest(years))
    } catch (err) {
      dispatch(getCarYearsRequest([]))
      console.log(err);
    }
  };

  const getCarModels = async (makeID: number, getYear = false) => {
    try {
      const data = await CarDetailsAPI.listModels(makeID);
      dispatch(getCarModelsRequest(data.data))
      if (getYear === true && job.model) {
        const model = data.data.find((item: any) => item.model === job.model);
        if (getSafeValue(model, 'id', '') !== '') {
          getCarYears(model?.id)
        }
      }
    } catch (err) {
      dispatch(getCarModelsRequest([]))
      console.log(err);
    }
  };

  const onJobMakeChange = (value: any) => {
    dispatch(getCarModelsRequest([]))
    dispatch(getCarYearsRequest([]))
    const make = makes.find((item: any) => item.make === value);
    if (getSafeValue(make, 'id', '') !== '') {
      getCarModels(make?.id, true)
    }
    if (job.color || job.vin || job.license) {
      setAdditionalInput(true);
    }
  }

  const handleUpdate = (index: number, name: string, value: any) => {
    dispatch(updateQuoteJob({ index, name, value }))
  }

  const handleChange = (name: string, value: any) => {
    handleUpdate(index, name, value);
    if (name === 'make') {
      handleUpdate(index, 'model', '');
      handleUpdate(index, 'year', '');
      onJobMakeChange(value)
    }
    if (name === 'model') {
      const model = models.find((item: any) => item.model === value);
      if (getSafeValue(model, 'id', '') !== '') {
        getCarYears(model?.id);
      }
      handleUpdate(index, 'year', '');
    }
  };

  const handleAdditionalInputChange = (e: any) => {
    setAdditionalInput(!additionalInput);
    handleUpdate(index, 'color', '');
    handleUpdate(index, 'vin', '');
    handleUpdate(index, 'license', '');
  };

  const handleJobTypeChange = (event: any) => {
    const { name, value } = event.target
    handleUpdate(index, name, value);
    if (value === 'Automotive') {
      handleUpdate(index, 'building_type', '');
      handleUpdate(index, 'service_type', '');
      handleUpdate(index, 'services', []);
    }

    if (value === 'Architectural') {
      handleUpdate(index, 'make', '');
      handleUpdate(index, 'model', '');
      handleUpdate(index, 'car_type', '');
      handleUpdate(index, 'year', '');
      handleUpdate(index, 'services', []);
    }
  }

  return (
    <>
      <Box className={styles.sectionWrappers}>
        <Grid container spacing={1} className={styles.textFieldWrapper}>
          <Grid item xs={6}>
            <Typography variant="subtitle2" fontWeight={600}>
              Job Type
            </Typography>
            <TextInput
              onChange={handleJobTypeChange}
              value={getSafeValue(job, 'type', '')}
              name="type"
              size="small"
              margin="dense"
              select
              className={styles.inputBold}
              fullWidth
              label="Select Job Type"
              onBlur={() => handleBlur('jobs[0].type')}
            >
              {JobTypes.map((jobType, i) => (
                <MenuItem value={jobType.value} key={i}>
                  {jobType.label}
                </MenuItem>
              ))}
            </TextInput>
            <ErrorMessage error={_.get(validationError, ['jobs[0].type'], '')} />
          </Grid>
        </Grid>

        {job.type === 'Automotive' && (
          <>
            <Grid container className={styles.textFieldWrapper}>
              <Box pt={1} pb={2}>
                <FormControlLabel
                  control={
                    <BpCheckbox
                      value={job.is_manual_input_vehicle}
                      name='is_manual_input_vehicle'
                      onChange={(e) => handleChange(e.target.name, e.target.checked)}
                    />
                  }
                  label="Manually input vehicle"
                />
              </Box>
            </Grid>

            <Grid container className={styles.textFieldWrapper}>
              <Box className={styles.flexWrapper}>
                <Box flex={1}>
                  <>
                    {job.is_manual_input_vehicle ? (
                      <TextInput
                        size="small"
                        margin="dense"
                        name="make"
                        value={getSafeValue(job, 'make', '')}
                        onChange={(e: any) => handleChange(e.target.name, e.target.value)}
                        label="Vehicle Make"
                        className={classNames(styles.flexWrapperSpacing)}
                        fullWidth
                        onBlur={() => handleBlur('jobs[0].make')}
                      />
                    ) : (
                      <Autocomplete
                        options={makes}
                        getOptionLabel={(option: any) => getSafeValue(option, 'make', '')}
                        onChange={(_, value: any) => handleChange('make', value?.make)}
                        value={getSafeValue(job, 'make', '') === '' ? null : { make: getSafeValue(job, 'make', '') }}
                        className={classNames(styles.flexWrapperSpacing)}
                        isOptionEqualToValue={(option: any, value: any) => option.make === value.make}
                        onBlur={() => handleBlur('jobs[0].make')}
                        renderInput={(params: any) => (
                          <TextInput
                            {...params}
                            size="small"
                            label="Vehicle Make"
                            margin="dense"
                          />
                        )}
                        fullWidth
                      />
                    )}
                    <ErrorMessage error={_.get(validationError, ['jobs[0].make'], '')} />
                  </>
                </Box>

                <Box flex={1}>
                  <>
                    {job.is_manual_input_vehicle ? (
                      <TextInput
                        size="small"
                        margin="dense"
                        name="model"
                        value={getSafeValue(job, 'model', '')}
                        onChange={(e: any) => handleChange(e.target.name, e.target.value)}
                        label="Vehicle Model"
                        className={classNames(styles.flexWrapperSpacing)}
                        onBlur={() => handleBlur('jobs[0].model')}
                        fullWidth
                      />
                    ) : (
                      <Autocomplete
                        options={models}
                        getOptionLabel={(option: any) => getSafeValue(option, 'model', '')}
                        onChange={(_, value: any) => handleChange('model', value?.model)}
                        value={getSafeValue(job, 'model', '') === '' ? null : { model: getSafeValue(job, 'model', '') }}
                        className={classNames(styles.flexWrapperSpacing)}
                        onBlur={() => handleBlur('jobs[0].model')}
                        isOptionEqualToValue={(option: any, value: any) => {
                          return option.model === value.model
                        }}
                        renderInput={(params: any) => (
                          <TextInput
                            {...params}
                            label="Vehicle Model"
                            size="small"
                            margin="dense"
                          />
                        )}
                        fullWidth
                        disabled={!job.make}
                      />
                    )}
                    <ErrorMessage error={_.get(validationError, ['jobs[0].model'], '')} />
                  </>
                </Box>
              </Box>

              <Box className={styles.flexWrapper}>
                <Box flex={1}>
                  <TextInput
                    onChange={(e: any) => handleChange(e.target.name, e.target.value)}
                    value={getSafeValue(job, 'car_type', '')}
                    name="car_type"
                    label="Car Body Type"
                    size="small"
                    margin="dense"
                    select
                    className={classNames(styles.flexWrapperSpacing)}
                    onBlur={() => handleBlur('jobs[0].car_type')}
                    fullWidth
                  >
                    {carTypes.map((type: string, i: number) => (
                      <MenuItem value={type} key={`carType_${i}`}>
                        {type}
                      </MenuItem>
                    ))}
                  </TextInput>
                  <ErrorMessage error={_.get(validationError, ['jobs[0].car_type'], '')} />
                </Box>
                <Box flex={1}>
                  {job.is_manual_input_vehicle ? (
                    <TextInput
                      size="small"
                      name="year"
                      label="Year"
                      margin="dense"
                      value={getSafeValue(job, 'year', '')}
                      onChange={(e: any) => handleChange(e.target.name, e.target.value)}
                      className={classNames(styles.flexWrapperSpacing)}
                      onBlur={() => handleBlur('jobs[0].year')}
                      fullWidth
                    />
                  ) : (
                    <TextInput
                      onChange={(e: any) => handleChange(e.target.name, e.target.value)}
                      value={getSafeValue(job, 'year', '')}
                      name="year"
                      label="Year"
                      size="small"
                      margin="dense"
                      select
                      className={classNames(styles.flexWrapperSpacing)}
                      fullWidth
                      SelectProps={{ MenuProps: { PaperProps: { sx: { maxHeight: 125 } } } }}
                      onBlur={() => handleBlur('jobs[0].year')}
                    >
                      {years.map((item: any, yearIndex: number) => (
                        <MenuItem value={item.year} key={yearIndex}>
                          {item.year}
                        </MenuItem>
                      ))}
                    </TextInput>
                  )}
                  <ErrorMessage error={_.get(validationError, ['jobs[0].year'], '')} />
                </Box>
              </Box>
            </Grid>

            <Grid container className={styles.textFieldWrapper}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <BpCheckbox
                      value={additionalInput}
                      checked={additionalInput}
                      onChange={handleAdditionalInputChange}
                    />
                  }
                  label="Add more vehicle details"
                />
              </Grid>

              {additionalInput && (
                <>
                  <Box pt={1} className={styles.flexWrapper}>
                    <TextInput
                      onChange={(e: any) => handleChange(e.target.name, e.target.value)}
                      value={getSafeValue(job, 'color', '')}
                      name="color"
                      label="Color"
                      size="small"
                      margin="dense"
                      className={styles.flexWrapperSpacing}
                      fullWidth
                    />
                    <TextInput
                      onChange={(e: any) => handleChange(e.target.name, e.target.value)}
                      value={getSafeValue(job, 'vin', '')}
                      name="vin"
                      label="Vin"
                      size="small"
                      margin="dense"
                      className={styles.flexWrapperSpacing}
                      fullWidth
                    />
                  </Box>

                  <Box className={styles.flexWrapper} style={{ width: '50%' }}>
                    <TextInput
                      onChange={(e: any) => handleChange(e.target.name, e.target.value)}
                      value={getSafeValue(job, 'license', '')}
                      name="license"
                      label="License Plate"
                      size="small"
                      margin="dense"
                      className={styles.flexWrapperSpacing}
                      fullWidth
                    />
                  </Box>
                </>)}
            </Grid>
          </>
        )}

        {job.type === 'Architectural' && (
          <>
            <Grid pt={2} container spacing={1} className={styles.textFieldWrapper}>
              <Grid item xs={6}>
                <TextInput
                  onChange={(e: any) => handleChange(e.target.name, e.target.value)}
                  value={getSafeValue(job, 'building_type', '')}
                  name="building_type"
                  label="Select Building Type"
                  size="small"
                  margin="dense"
                  select
                  className={styles.textField2}
                  onBlur={() => handleBlur('jobs[0].building_type')}
                  fullWidth
                >
                  <MenuItem value="Residential">Residential</MenuItem>
                  <MenuItem value="Commercial">Commercial</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </TextInput>
                <ErrorMessage error={_.get(validationError, ['jobs[0].building_type'], '')} />
              </Grid>

              <Grid item xs={6}>
                <TextInput
                  onChange={(e: any) => handleChange(e.target.name, e.target.value)}
                  value={getSafeValue(job, 'service_type', '')}
                  name="service_type"
                  label="Select Service Type"
                  size="small"
                  margin="dense"
                  select
                  className={styles.textField2}
                  fullWidth
                  onBlur={() => handleBlur('jobs[0].service_type')}
                >
                  <MenuItem value="Installation">Installation</MenuItem>
                  <MenuItem value="Consultation">Consultation</MenuItem>
                </TextInput>
                <ErrorMessage error={_.get(validationError, ['jobs[0].service_type'], '')} />
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </>
  );
}

export default JobDetail;
