import ContactAPI from 'api/resources/contact';
import { useState, useEffect } from 'react';

const useLocationData = () => {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const fetchStates = async () => {
    try {
      const response = await ContactAPI.getState();
      setStates(response.data);
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  };

  const fetchCities = async (state: any) => {
    try {
      const response = await ContactAPI.getCity(state);
      setCities(response.data);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  // Automatically fetch states when the hook is used
  useEffect(() => {
    fetchStates();
  }, []);

  return { states, cities, setCities, fetchCities };
};

export default useLocationData;
