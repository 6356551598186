import { Box, Typography } from "@mui/material";
import { formatPhoneNumber, getFullAddress, getSafeValue } from "utils";
import Style from "./Contact.module.scss";

function Contact({ businessInformation }: any) {
  return (
    <Box>
      <Box className={Style.container}>
        <Typography className={Style.title}>
          Contact {businessInformation.business_name}
        </Typography>
        <Box>
          {businessInformation.hide_website_from_profile === false && <Box className={Style.gridFlex}>
            <Typography className={Style.lable}>Website:</Typography>
            <Typography className={Style.data}>{getSafeValue(businessInformation, 'business_website', '---')}</Typography>
          </Box>}
          <Box className={Style.gridFlex}>
            <Typography className={Style.lable}>Phone:</Typography>
            <Typography className={`${Style.data} cursor`} onClick={() => window.open(`tel:${businessInformation?.contact_number}`, '_self')}>{formatPhoneNumber(getSafeValue(businessInformation, 'contact_number', '---'))}</Typography>
          </Box>
          {businessInformation.hide_email_from_profile === false && <Box className={Style.gridFlex}>
            <Typography className={Style.lable}>Email:</Typography>
            <Typography className={Style.data}>{getSafeValue(businessInformation, 'email', '---')}</Typography>
          </Box>}
          {businessInformation.hide_address_from_profile === false && businessInformation.address &&
            <Box className={Style.gridFlex}>
              <Typography className={Style.lable}>Address:</Typography>
              <Typography className={Style.data}>{getFullAddress(businessInformation.address)}</Typography>
            </Box>}
        </Box>
      </Box>
    </Box>
  );
}

export default Contact;
