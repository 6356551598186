import { Box } from '@mui/material';
import { useAppSelector } from 'redux/hooks';
import { getQuickQuote, selectQuickQuoteInfo } from 'redux/selectors/quickQuoteSelector';
import ApplicationDetails from './ApplicationDetails';
import ArchitecturalApplicationDetails from './ArchitecturalApplicationDetails';

function Applications() {
  const quickQuote: any = useAppSelector((state) => getQuickQuote(state));
  const { errors: validationError }: any = useAppSelector((state) => selectQuickQuoteInfo(state));
  const { jobs } = quickQuote

  return (
    <>
      {
        jobs.map((job: any, i: number) => (
          <Box pt={3} key={i}>
            {job.type === 'Automotive' &&
              <ApplicationDetails
                job={job}
                index={i} 
                validationError={validationError}
              />
            }

            {job.type === 'Architectural' &&
              <ArchitecturalApplicationDetails
                job={job}
                index={i}
                validationError={validationError}
              />
            }
          </Box>
        )
        )}
    </>
  );
}

export default Applications;
